import "core-js";
import * as $ from "jquery";
import { Analytics } from "../services/analytics";
import { AuthenticationHandler } from "../common/authentication-handler";
import { Pwa } from "../services/pwa";
import { ResetPassword } from "./types/reset-password";

window["jQuery"] = $;
require("bootstrap");
require("datatables.net-bs");
require("datatables.net-buttons-bs");
require("datatables.net-fixedheader-bs");
require("datatables.net-responsive-bs");

const loadApplication = async () => {
    const {Application} = await import(/* webpackChunkName: "application" */ "./types/application");
    (<any>Application).initialize();
};

const loadLogin = async () => {
    const {Login} = await import(/* webpackChunkName: "login" */ "./types/login");
    new Login();
};

if ("serviceWorker" in navigator) {
    window.addEventListener("load", function () {
        navigator.serviceWorker.register("/sw.js").then(function (registration) {
            console.log("ServiceWorker registration successful with scope: ", registration.scope);
        }, function (err) {
            console.log("ServiceWorker registration failed: ", err);
        });
    });
    window.addEventListener("beforeinstallprompt", function (e) {
        e.preventDefault();
        Pwa.deferredPrompt = e;
    });
}

$(async () => {
    $.ajaxSetup({
        beforeSend(xhr) {
            xhr.setRequestHeader(AuthenticationHandler.controlTokenParamName, localStorage[AuthenticationHandler.controlTokenParamName]);
        }
    });
    Analytics.init();

    if (await AuthenticationHandler.isLoggedIn()) {
        loadApplication();
    } else {
        if (window.location.href.indexOf("logInUrl=") !== -1) {
            const logInUrl = decodeURIComponent(window.location.href.split("logInUrl=")[1].split("&")[0].split("#")[0]);
            await AuthenticationHandler.logInWithUrl(logInUrl);
        } else {
            window.location.replace(window.location.origin + "/auth.html" + window.location.search + window.location.hash);
        }
    }
});
